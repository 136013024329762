import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"
import { graphql, Link } from "gatsby"
import {
  galleriesContainer,
  galleryCard,
  galleryCardTitle,
  galleryCardThumbnail,
} from "@pagesStyles/gallery.module.css"

export const query = graphql`
  query KinderardenGallery {
    allStrapiGaleriaPrzedszkole {
      edges {
        node {
          Opis_zdjec
          slug
          Zdjecia {
            formats {
              small {
                url
              }
            }
          }
        }
      }
    }
  }
`

function KindergardenGallery({ data }) {
  const baseUrl =
    typeof process.env.IMAGE_BASE_URL !== "undefined"
      ? process.env.IMAGE_BASE_URL
      : ""

  const galleries = data.allStrapiGaleriaPrzedszkole.edges.map(edge => (
    <Link to={edge.node.slug} className={galleryCard}>
      <h2 className={galleryCardTitle}>{edge.node.Opis_zdjec}</h2>
      <img
        className={galleryCardThumbnail}
        src={baseUrl + edge.node.Zdjecia[0].formats.small.url}
        alt=""
      />
    </Link>
  ))

  return (
    <Layout menuType="kindergarden">
      <SEO title="Galeria przedszkola - ZSP Nidek" />
      <h1>Galeria przedszkola</h1>
      <div className={galleriesContainer}>{galleries}</div>
    </Layout>
  )
}

export default KindergardenGallery
